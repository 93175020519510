@import "../../../variables.scss";

.button {
    color: var(--light-silver);
    cursor: pointer;

    :hover {
        color: $theme;
    }

    span {
        transition: 0.3s;
    }

    &.add {
        span {
            background-color: var(--light-silver);
            border-radius: 100%;
            color: $white;
            width: 21px;
            height: 21px;
        }
    }
}

// The following is per field type

.delete {
    :hover {
        color: $danger !important;
    }
}

.buttonsDownUp {
    display: flex;
    margin-bottom: 1rem;
    align-items: flex-end;
    justify-content: flex-start;
}

.wrappEditDeleteAdd {
    width: 100%;
    display: flex;
    .name {
        margin-right: auto;
    }
    span.button {
        margin-left: 0.5rem;
        border-radius: 0.2rem;
        span {
            margin-left: 0;
            border: 0;
        }
    }
    .button,
    .name {
        display: flex;
        align-items: center;
    }
}

.sortableTree {
    margin-top: 1rem;
}

.searchCategory {
    min-width: 30%;
    border: 1px solid rgba(40, 168, 110, 0.5);
    padding: 0 1rem;
    border-radius: 0.25rem;
    font-family: "Montserrat";
    &:focus {
        outline: none;
        border: 2px solid $theme;
    }
}
