@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
@import "react-dropdown-tree-select/dist/styles.css";
@import '@nosferatu500/react-sortable-tree/style.css';


.theme-light {
    --login-img-background: #d9e8fc;
    --main-color: #42b732;
    --second-color: #1b1b1b;
    --light-silver: #b3b3b3;
    --dark-silver: #6d6d6d;
    --text-color: #1b1b1b;
    --small-text-color: #6c757d;
    --bg-color: #ffffff;
    --main-bg-color: #ecf0fa;
    --third-color: #000000;
    --shadow-color: #dadee8;
    --shadow-parameters: -8px 12px 18px 0;
    --forms-bg: #ffffff;
    --forms-border: #b3b3b3;
    --forms-border-focus: #42b732;
    --login-forms-border: #b3b3b3;
    --white-side-color: #ffffff;
    --sidebar-bg-color: #1a2226;
    --table-border: #dee2e6;
    --item-table-color: #212529;
    --card-border-color: #dfdfdf;
    --alert-warning-color: #fff3cd;
    --alert-primary-color: #cce5ff;
    --alert-success-color: #d4edda;
    --alert-secondary-color: #e2e3e5;
    --alert-info-color: #d1ecf1;
    --alert-border-color: rgba(0, 0, 0, 0.125);
    --card-divader-color: rgba(0, 0, 0, 0.1);
    --transparent-blue: #1397a3;
    --categories-hover-bg: #f8f9fa;
    --search-bg: #ecf0fa;
    --tabs-bg: #ecf0fa;
    --input-disabled-color: #e9ecef;
    --input-group-color: #efefef;
    --text-login-color: #212529;
    --login-white-background: #ffffff;
    --bg-green: #32eb844d;
    --theme: #28a86e;
    --green: #43fa2b;
    --red: #d32f2f;
    --orange: #ff9800;
    --blue: #2196f3;
    --petroleum: #17a2b9;
    --white: #ffffff;
    --dashboardBlue: #3d76bb;
    --dashboardBlueOp: #3d76bb66;
    --dashboardGreenOp: #28a86e66;
    --dashboardOrange: #ffc000;
    --dashboardOrangeOp: #ffc00066;

    --statusNew: #bae1ff;
    --statusCompleted: #baffc9;
    --statusCanceled: #ffb3ba;
    --statusPending: #ffffba;
    --statusReady: #ffdfba;
    --statusDelivered: #ededaf;
    --statusTaken: #caacf9;
    --statusArchived: #f2f2f2;
    --statusConfirmed: #17a2b93d;
    --statusOrderPanding: #ddb78a;
    --statusOther: #eef8ff;
}

.theme-dark {
    --login-img-background: #d9e8fc;
    --main-color: #42b732;
    --second-color: #131b1b;
    --light-silver: #b3b3b3;
    --text-color: #ffffff;
    --small-text-color: #ffffff;
    --bg-color: #323c48;
    --main-bg-color: #2a323c;
    --third-color: #ffffff;
    --shadow-color: #00000040;
    --shadow-parameters: -3px 0px 13px 0;
    --forms-bg: #3b4654;
    --forms-border: #4a5869;
    --forms-border-focus: #5f7187;
    --login-forms-border: #b3b3b3;
    --white-side-color: #1b1b1b;
    --sidebar-bg-color: #ffffff;
    --table-border: #5f7187;
    --item-table-color: #98a4b9;
    --card-border-color: #dee2e6;
    --alert-warning-color: #fff3cd60;
    --alert-primary-color: #cce5ff60;
    --alert-success-color: #d4edda60;
    --alert-secondary-color: #e2e3e560;
    --alert-info-color: #d1ecf160;
    --alert-border-color: rgba(255, 255, 255, 0.125);
    --card-divader-color: rgba(255, 255, 255, 0.2);
    --categories-hover-bg: #3b4654;
    --search-bg: #3b4654;
    --tabs-bg: #3b4654;
    --input-disabled-color: #4a5869;
    --input-group-color: #4a5869;
    --text-login-color: #212529;
    --login-white-background: #ffffff;
}

.hidden-for-next-version {
    display: none !important;
}

$arrow-black: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");

body {
    font-family: "Montserrat", sans-serif !important;
    font-weight: 400 !important;
    margin: 0;
}
.router-container {
    // padding-left: 90px;
    color: var(--second-color);
    background-color: var(--main-bg-color);
    // width: calc(100% - 90px);
    min-height: 100vh;
}
.router-container.side-open {
    width: calc(100% - 240px);
    padding-left: 240px;
}

.main-wrapper {
    padding: 2rem;
}

form label {
    font-size: 0.875rem !important;
}

/*---------------------- SORTABLE TREE START -----------------*/
.rst__rowWrapper {
    .rst__row {
        height: 2.5rem;
        .rst__rowContents {
            justify-content: flex-start;
            min-width: 60%;
            box-shadow: none;
            border-radius: 0 0.25rem 0.25rem 0;
            .rst__rowToolbar,
            .rst__toolbarButton {
                width: 100%;
            }
        }
        .rst__moveHandle {
            box-shadow: none;
            width: 2.5rem;
            border-color: #c7c7c7;
            border-radius: 0.25rem 0 0 0.25rem;
        }
    }
}

.rst__expandButton,
.rst__collapseButton {
    box-shadow: 0 0 0 1px #bbb;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineHalfHorizontalRight::after,
.rst__lineFullVertical::after,
.rst__lineChildren::after {
    background-color: #bbb;
}

.rst__collapseButton:focus,
.rst__expandButton:focus {
    box-shadow: 0 0 0 1px #28a86e, 0 0 1px 3px #82c2a5;
}

.rst__node,
.rst__nodeContent {
    min-width: 30%;
}

.rst__rowSearchMatch {
    outline: 2px solid rgba(50, 155, 235, 0.6);
}
.rst__rowSearchFocus {
    outline: 2px solid rgba(96, 192, 133, 0.6);
}

/*---------------------- SORTABLE TREE END -----------------*/

/*---------------------- DASHBOARD -----------------*/
.dashboardTable {
    // background-color: red;
    thead {
        tr {
            th {
                border-bottom: 0;
                padding: 0.5rem;
            }
        }
    }
    tbody {
        tr {
            td {
                border-bottom: 0;
                padding: 0.7rem;
                font-size: 0.813rem;

                a {
                    color: var(--theme);
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    display: -webkit-box;
                    line-height: initial;
                }
            }
        }
    }
}

/*---------------------- SLIDER -----------------*/
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 50px;
}

/*---------------- COMMON STYLES START -----------------*/
.accordion-button:not(.collapsed)::after {
    background-image: $arrow-black;
}
.form-control {
    box-shadow: unset;
    outline: unset;
    border-radius: unset;
    border-color: var(--forms-border);
    background-color: var(--forms-bg);
    color: var(--third-color);
    border-radius: 0.25rem;
    line-height: 0px;
    font-size: 0.9rem;
}
.login-form-control {
    border-color: var(--login-forms-border);
    border-radius: 5px;
}
.form-control:focus {
    box-shadow: unset;
    outline: unset;
}
.required:after {
    content: " *";
    color: red;
}
.error-text {
    display: block;
    color: red;
    font-size: 0.675rem;
    line-height: 0.675rem;
    height: 0.675rem;
    margin: 0.25rem 0;
}
.no-error-text .error-text {
    display: none;
}
.info-text {
    display: block;
    color: #6c757d;
    font-size: 0.675rem;
    line-height: 0.675rem;
    height: 0.675rem;
    margin: 0.25rem 0;
}
.invalid {
    border-color: red !important;
}
a {
    text-decoration: unset;
}
.font-14 {
    font-size: 0.875rem;
}
.form-control-label {
    font-size: 14px;
    margin-bottom: 0;
    color: var(--third-color);
}
.settings-list {
    .Mui-selected {
        background-color: var(--main-color) !important;
        color: white !important;
    }
}
.settings-pagination {
    .Mui-selected {
        background-color: var(--main-color) !important;
        color: white !important;
    }
}

.MuiDataGrid-toolbarContainer button {
    display: none !important;
}

.MuiDataGrid-footerContainer {
    display: flex;
    flex-direction: column;
    padding-top: 0.7rem;
    p {
        margin-bottom: 0;
        margin-top: 0;
    }
}

.remember-checkbox {
    .form-check {
        input {
            border-radius: unset;
            box-shadow: unset;
            border-color: var(--login-forms-border);
            background-color: var(--login-white-background);
        }
        input:checked {
            border-color: var(--login-forms-border);
            background-color: var(--login-white-background);
        }
        input:checked[type="checkbox"] {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='black' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
            background-size: 70% 70%;
            background-repeat: no-repeat;
            background-position: center;
        }
        label {
            font-size: 16px;
            color: var(--side-color);
        }
    }
}
.btn-control:disabled {
    cursor: not-allowed !important;
}
.btn-control:disabled:hover {
    cursor: not-allowed;
}
.btn-control.button-outline-black {
    padding: 0.375rem 0.75rem;
    border: 1px solid var(--login-forms-border);
    font-weight: 600;
    background-color: var(--login-white-background);
    border-radius: 0;
}
.btn:focus {
    box-shadow: unset;
}
.btn-control.button-add {
    background-color: #17a2b8;
    color: white;
    cursor: pointer;
    border: none;
}

.btn-control.button-add:hover {
    background-color: #ecf0fa;
    color: var(--second-color);
    cursor: pointer;
}

.btn-control.cancel-btn {
    background-color: #dc3545;
    border: 1px solid #dc3545;
    color: white !important;
    font-weight: 600;
    padding: 0.4rem 1rem;
    border-radius: 0;
    display: inline-block;
    height: 38px;
}

.btn-control.cancel-btn:hover {
    background-color: white;
    border: 1px solid var(--second-color);
    color: var(--second-color) !important;
}
.btn-control.save-btn {
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    color: white;
    font-weight: 600;
    padding: 0.4rem 1rem;
    border-radius: 0;
    display: inline-block;
}

.btn-control.save-btn:hover {
    background-color: white;
    border: 1px solid var(--second-color);
    color: var(--second-color);
}
.btn-control.btn-add-details {
    color: #ffffff;
    background-color: #28a745;
    border-color: #28a745;
}
.btn-control.btn-add-details:hover,
.btn-control.btn-add-details:active,
.btn-control.btn-add-details:focus {
    color: #ffffff;
    background-color: #218838;
    border-color: #1e7e34;
}
.btn-control.btn-delete-details {
    color: #ffffff;
    background-color: #dc3545;
    border-color: #dc3545;
}
.btn-control.btn-add-details:disabled,
.btn-control.btn-delete-details:disabled {
    cursor: not-allowed;
    opacity: 1;
    pointer-events: all;
}
.btn-control.btn-delete-details:hover,
.btn-control.btn-delete-details:active,
.btn-control.btn-delete-details:focus {
    color: #ffffff;
    background-color: #c82333;
    border-color: #bd2130;
}
.btn-control.btn-show-details {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    border-radius: unset;
    margin: unset;
}
.btn-control.btn-show-details:hover,
.btn-control.btn-show-details:active,
.btn-control.btn-show-details:focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}
.btn-control.auth-transparent-button {
    font-size: 0.875rem;
    background: transparent;
    border: unset;
    color: var(--textPrimaryColor);
    font-weight: 600;
    padding: unset;
}
.btn-control.auth-transparent-button:hover {
    text-decoration: underline;
}

.col-p-1 {
    padding: 0 15px;
}
.col-pr-2 {
    padding-right: 30px;
}
.row-m0 {
    margin: unset;
    --bs-gutter-x: unset;
}
.Toastify {
    word-break: break-word;
    white-space: pre-line;
}
.select-style .select__control--menu-is-open {
    color: #212529;
    background-color: #ffffff;
    border-color: #86b7fe;
}
.select-style .select__control--menu-is-open .select__single-value {
    color: #212529;
}
.select-style.invalid > div {
    border-color: red !important;
}
.select-style {
    padding: unset;
    border: unset;
    > div {
        background: transparent;
        box-shadow: unset;
        border-color: var(--forms-border);
        height: 39.59px;
        border-radius: unset;
        color: var(--third-color);
    }
    .select__single-value {
        color: var(--third-color);
    }
    > div:hover {
        border-color: var(--forms-border);
    }
    .css-1okebmr-indicatorSeparator {
        display: none;
    }
    .select__menu-list {
        background-color: var(--forms-bg);
        color: var(--third-color);
    }
    .select__menu-list > div:hover,
    .select__menu-list > div.select__option--is-focused,
    .select__menu-list > div.css-9gakcf-option {
        background-color: var(--bg-color);
        color: var(--main-color);
    }
}
.dropdown-tree-selected.dropdown-tree-style.react-dropdown-tree-select {
    .tag-list .tag-item:first-child {
        position: absolute;
        top: 11px;
        padding: 0 1rem;
    }
    .tag-item .tag {
        background: transparent;
        border: unset;
        line-height: 11px;
        color: var(--third-color);
    }
    .tag-remove {
        color: var(--third-color);
    }
    .tag-item .tag button:hover {
        opacity: 0.6;
    }
}
.dropdown-tree-selected.dropdown-tree-style.dropdown-tree-focus {
    input.search {
        color: #212529;
        background-color: #ffffff;
        border-color: #86b7fe;
    }
}
.dropdown-tree-selected.dropdown-tree-style.dropdown-tree-focus.react-dropdown-tree-select {
    .tag-item .tag {
        color: #212529;
    }
}
.dropdown-tree-style.react-dropdown-tree-select {
    padding: unset;
    border: unset;
    .dropdown {
        display: block;
    }
    .dropdown-trigger {
        height: 48px;
        width: 100%;
        padding: unset;
        border: unset;
    }
    .tag-list,
    .tag-item {
        width: 100%;
        margin: unset;
    }
    input.search {
        width: 100%;
        height: 39.59px;
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        box-shadow: unset;
        border: 1px solid #ced4da;
        outline: unset;
        border-radius: 0.25rem;
        border-color: var(--forms-border);
        background-color: var(--forms-bg);
        color: var(--third-color);
    }
    .dropdown .dropdown-trigger.arrow.top:after {
        color: var(--third-color);
        right: 10px;
        top: 14px;
        position: absolute;
        margin: unset;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        width: 1.25rem;
        height: 1.25rem;
        content: "";
        transition: transform 0.2s ease-in-out;
    }
    .dropdown .dropdown-trigger.arrow.bottom:after {
        color: var(--third-color);
        right: 10px;
        top: 14px;
        position: absolute;
        margin: unset;
        background-image: $arrow-black;
        background-repeat: no-repeat;
        transform: rotate(-180deg);
        width: 1.25rem;
        height: 1.25rem;
        content: "";
        transition: transform 0.2s ease-in-out;
    }
    .dropdown-content {
        width: 100%;
        padding: 4px 0;
        z-index: 2;
        background-color: var(--forms-bg);
        color: var(--third-color);
        .node.tree {
            background-color: transparent;
        }
        .toggle {
            padding-left: 0.25rem;
        }
        .node:hover,
        .node.checked,
        .node.focused {
            background-color: var(--bg-color);
            color: var(--main-color);
        }
    }
    .node-label {
        font-size: 0.875rem;
    }
}
.dropdown-tree-style.react-dropdown-tree-select.dropdown-tree-multiselect-style {
    .tag-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        padding: 0 2rem 0 1rem;
        .tag-item {
            width: auto;
        }
        .tag-item:first-child {
            position: unset;
            padding: unset;
        }
        .tag-item:last-child {
            position: absolute;
            width: 100%;
            right: 0;
        }
        .tag-item:not(:last-child) {
            z-index: 1;
        }
    }
}
.dropdown-tree-selected.dropdown-tree-style.dropdown-tree-focus.dropdown-tree-multiselect-style {
    .tag-list .tag-item:last-child {
        z-index: 2;
    }
}
/*---------------- COMMON STYLES END -----------------*/
// .col-sm-2 {
//     width: 16.66667% !important;
//     box-sizing: border-box;
// }

// .col-sm-4 {
//     width: 33.33333% !important;
//     box-sizing: border-box;
// }

// .col-sm-6 {
//     width: 50% !important;
//     box-sizing: border-box;
// }

// .col-sm-8 {
//     width: 66.66667% !important;
//     box-sizing: border-box;
// }

// .col-sm-10 {
//     width: 83.33333% !important;
//     box-sizing: border-box;
// }

// .col-sm-12 {
//     width: 100% !important;
//     box-sizing: border-box;
// }
/*---------------------- LOGIN START -----------------*/

button.buttonLogin {
    margin-top: 1rem;
    padding: 0.6rem 3rem;
    border: unset;
    background-color: var(--main-color);
    border-radius: 0.313rem;
    color: #ffffff !important;
    width: fit-content;
    text-transform: none;
    &:hover {
        background-color: rgba(66, 183, 50, 1) !important;
    }
    &:disabled {
        cursor: not-allowed !important;
        pointer-events: inherit;
    }
}

/*-------------------LOGIN END -----------------------*/
/*------------------- SIDEBAR START ------------------*/
#sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 90px;
    max-width: 90px;
    background: var(--sidebar-bg-color);
    color: white;
    z-index: 102;
    box-shadow: 5px 7px 26px -5px var(--shadow-color);
    display: none;
}

#sidebarCollapse svg:hover {
    cursor: pointer;
}
.navbar #sidebarCollapse svg {
    color: var(--third-color);
}
li.dropdown-item-custom.open .dropdown-menu {
    display: block !important;
}

#sidebar .dropdown-menu {
    position: relative !important;
    width: 100%;
    padding: 0.5rem 0 1.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: transparent;
    border: transparent;
    border-radius: 0;
    transform: translate3d(0px, 0px, 0px) !important;
}

#sidebar .sidebar-welcome {
    padding: 0 15px;
}

#sidebar .dropdown-toggle::after {
    display: none;
}

#sidebar .sidebar-welcome p {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

#sidebar .sidebar-categories {
    padding: 10px 15px;
    background-color: #32eb844d;
}

#sidebar .sidebar-categories p {
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 600;
    font-size: 14px;
    color: var(--white-side-color);
}

.side-open #sidebar {
    min-width: 240px;
    max-width: 240px;
    display: block;
}

#sidebar ul.components li {
    font-size: 12px;
}

#sidebar ul.components li a {
    padding: 10px 5px;
    text-align: center;
}

.side-open #sidebar ul.components li a {
    padding: 0.938rem;
    text-align: left;
}

#sidebar .desktop-logo {
    display: none;
}

.side-open #sidebar .desktop-logo {
    display: block;
}

.side-open #sidebar .mobile-logo {
    display: none;
}

#sidebar .mobile-logo {
    display: block;
    width: 60px !important;
}

#sidebar .scroll-view {
    height: calc(100vh - 70px);
    overflow: auto;
    overflow-x: hidden;
}
.side-open #sidebar .scroll-view {
    height: calc(100vh - 250px);
}

#sidebar .scroll-view .category-item:last-child {
    margin-bottom: 1.5rem;
}

#sidebar .scroll-view::-webkit-scrollbar {
    width: 0.3em;
}

#sidebar .scroll-view::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#sidebar .scroll-view::-webkit-scrollbar-thumb {
    background-color: var(--main-color);
    outline: 1px solid slategrey;
}

.side-open #sidebar .logo {
    display: block;
    color: var(--second-color);
    padding: 10px 15px;
    border-bottom: 1px solid rgba(50, 155, 235, 0.3);
}

#sidebar .logo {
    display: block;
    color: var(--second-color);
    padding: 10px 15px;
    border-bottom: 1px solid #eae8f1;
    height: 68px;
}

#sidebar .logo img {
    width: 100%;
}

@media (prefers-reduced-motion: reduce) {
    #sidebar .logo {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

#sidebar ul.components {
    padding: 0;
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    margin: 0;
    margin: 1rem 0;
}

@media (prefers-reduced-motion: reduce) {
    #sidebar ul.components {
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
    }
}

#sidebar ul li > ul {
    margin-left: 10px;
}

#sidebar ul li > ul li {
    font-size: 14px;
}

#sidebar ul .dropdown-item-custom .dropdown-menu a {
    background-color: transparent;
    border: none;
    padding: 2px 15px 2px 20px;
}

#sidebar .dropdown-item-custom a span svg {
    float: right;
    top: 3px;
    position: relative;
}

#sidebar ul .dropdown-item-custom .dropdown-menu a svg {
    font-size: 10px;
    top: -2px;
    position: relative;
    margin-right: 2px;
}

#sidebar ul .dropdown-item-custom .dropdown-menu a:hover {
    color: var(--main-color);
    background-color: transparent;
    border: none;
}

#sidebar ul li a {
    padding: 10px 15px;
    display: block;
    font-size: 14px;
    font-weight: 300;
    color: var(--white-side-color);
    border-left: 5px solid transparent;
}

@media (max-width: 991.98px) {
    #sidebar ul li a i {
        display: block;
    }
}

#sidebar ul li a:hover {
    color: var(--white-side-color);
    border-left: 5px solid var(--main-color);
    background-color: rgba(50, 155, 235, 0.3);
    text-decoration: none;
}

#sidebar ul li a.active {
    background: rgba(50, 155, 235, 0.3);
    color: var(--white-side-color);
    border-left: 5px solid var(--main-color);
}

@media (max-width: 991.98px) {
    #sidebar {
        min-width: 80px;
        max-width: 80px;
        text-align: center;
        // margin-left: -80px !important;
    }
    #sidebar .active {
        margin-left: 0 !important;
    }
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 991.98px) {
    #sidebarCollapse span {
        display: none;
    }
}

#sidebar .sidebar-categories {
    display: none;
}

.side-open #sidebar .sidebar-categories {
    display: block;
}

#sidebar .sidebar-welcome {
    display: none;
}

.side-open #sidebar .sidebar-welcome {
    display: flex;
    color: var(--white-side-color);
    flex-direction: column;
    justify-content: center;
}

#sidebar ul li a span {
    font-size: 1rem;
    margin: 0 0.313rem 0.125rem 0;
}

#sidebar ul.components li a {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.side-open #sidebar ul.components li a {
    flex-direction: row;
}
/*------------------- SIDEBAR END ------------------*/

/*------------------- HEADER START -----------------*/
.navbar-sections-container {
    justify-content: space-between;
}
.badge-circle-red {
    background-color: red;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50px;
    top: -7px;
    position: relative;
    left: -5px;
}

.badge-circle-green {
    background-color: green;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50px;
    top: -7px;
    position: relative;
    left: -5px;
}

.navbar-light .navbar-nav button.nav-link {
    border: none;
    background-color: transparent;
    outline: none;
}

.navbar-light .navbar-nav .nav-link svg {
    font-size: 17px;
    color: var(--third-color);
}

.navbar-light .navbar-nav .theme-switcher-holder {
    padding: 0.5rem 0;
}

.nav-item.user-icon {
    width: 46px;
    height: 46px;
    margin-left: 1rem;
    button.nav-link {
        width: 100%;
        height: 100%;
        background-color: var(--sidebar-bg-color);
        border-radius: 50%;
        overflow: hidden;
    }
    h5 {
        color: var(--white-side-color);
        font-size: 1.125rem;
        margin: unset;
    }
}

.nav-item.user-icon .nav-link {
    padding: 0;
}
.nav-item.user-icon .nav-link::after {
    content: none;
}
.nav-item.user-icon .nav-link:focus {
    box-shadow: none;
}

.btn-toggle.btn-xs:focus {
    outline: none;
    box-shadow: none;
}

.btn-toggle {
    margin: 0 4rem;
    padding: 0;
    position: relative;
    border: none;
    height: 1.5rem;
    width: 3rem;
    border-radius: 1.5rem;
    color: #6b7381;
    background: #bdc1c8;
}

.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active {
    outline: none;
}

.btn-toggle:before,
.btn-toggle:after {
    line-height: 1.5rem;
    width: 4rem;
    text-align: center;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
}

.btn-toggle:before {
    content: "Off";
    left: -4rem;
}

.btn-toggle:after {
    content: "On";
    right: -4rem;
    opacity: 0.5;
}

.btn-toggle > .handle {
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 1.125rem;
    background: #ffffff;
    transition: left 0.25s;
}

.btn-toggle.active {
    transition: background-color 0.25s;
}

.btn-toggle.active > .handle {
    left: 1.6875rem;
    transition: left 0.25s;
}

.btn-toggle.active:before {
    opacity: 0.5;
}

.btn-toggle.active:after {
    opacity: 1;
}

.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
    display: none;
}

.btn-toggle:before,
.btn-toggle:after {
    color: #6b7381;
}

.btn-toggle.active {
    background-color: var(--main-color);
}

.btn-toggle.btn-sm.btn-xs:before,
.btn-toggle.btn-sm.btn-xs:after {
    display: none;
}

.btn-toggle.btn-xs {
    margin: 0 0;
    margin-right: 1rem !important;
    padding: 0;
    position: relative;
    border: none;
    height: 1rem;
    width: 2rem;
    border-radius: 1rem;
}

.btn-toggle.btn-xs:focus,
.btn-toggle.btn-xs.focus,
.btn-toggle.btn-xs:focus.active,
.btn-toggle.btn-xs.focus.active {
    outline: none;
}

.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
    line-height: 1rem;
    width: 0;
    text-align: center;
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
}

.btn-toggle.btn-xs:before {
    content: "Off";
    left: 0;
}

.btn-toggle.btn-xs:after {
    content: "On";
    right: 0;
    opacity: 0.5;
}

.btn-toggle.btn-xs > .handle {
    position: absolute;
    top: 0.125rem;
    left: 0.125rem;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.75rem;
    background: #ffffff;
    transition: left 0.25s;
}

.btn-toggle.btn-xs.active {
    transition: background-color 0.25s;
}

.btn-toggle.btn-xs.active > .handle {
    left: 1.125rem;
    transition: left 0.25s;
}
.bg-light {
    height: 63px;
    background-color: var(--bg-color) !important;
    box-shadow: 5px 7px 26px -5px var(--shadow-color);
    z-index: 101 !important;
    width: 100% !important;
    left: 0;
    padding-left: 90px;
    position: fixed;
}
.side-open .bg-light {
    padding-left: 250px;
}

#navbarSupportedContent {
    justify-content: space-evenly;
}
.navbar-search .rounded {
    background-color: var(--search-bg);
}
.navbar-search .form-control {
    background-color: var(--search-bg);
}
.navbar-search .btn-link svg {
    color: #b4bdce;
}

.navbar-search .form-control {
    font-size: 13px;
    margin-top: 2px;
    padding-left: 1rem;
}
/*-------------------- HEADER END ------------------*/

/*------------------ LOADER START ------------------*/
// #spinner {
//     -webkit-animation: frames 1s infinite linear;
//     animation: frames 1s infinite linear;
//     background: transparent;
//     border: 1vw solid #ffffff;
//     border-radius: 100%;
//     border-top-color: var(--main-color);
//     width: 5vw;
//     height: 5vw;
//     opacity: 1;
//     padding: 0;
//     position: absolute;
//     z-index: 999;
// }
// @keyframes frames {
//     0% {
//         -webkit-transform: rotate(0deg);
//         transform: rotate(0deg);
//     }
//     100% {
//         -webkit-transform: rotate(359deg);
//         transform: rotate(359deg);
//     }
// }

// #pause {
//     display: block;
//     background: rgba(0, 0, 0, 0.66) no-repeat 0 0;
//     width: 100%;
//     height: 100%;
//     position: fixed;
//     bottom: 0;
//     left: 0;
//     z-index: 1000;
// }
/*------------------ LOADER END ------------------*/

/*------------------ TABS START ------------------*/
.tabs-container {
    padding: 20px;
}
.tabs-wrapper .nav-link.active {
    color: #ffffff !important;
    background-color: var(--main-color);
    border-color: var(--main-color);
    border-radius: 5px;
    //font-weight: 600;
    font-size: 14px;
    line-height: 24px;
}
.tabs-wrapper .nav-link:focus,
.tabs-wrapper .nav-link:hover {
    color: #ffffff !important;
    background-color: var(--main-color);
    border-color: var(--main-color);
    border-radius: 5px;
    //font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    isolation: unset;
}

.tabs-wrapper .nav-link {
    color: var(--third-color);
    position: relative;
    border: 1px solid var(--tabs-bg);
    border-radius: 5px;
    background-color: var(--tabs-bg);
    //font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    isolation: unset;
}

.tabs-wrapper {
    border-bottom: 1px solid var(--card-divader-color);
    width: 100%;
}
.tabs-container {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
}

.tabs-wrapper .nav-link svg {
    top: -5px;
    position: absolute;
    right: -5px;
    font-size: 17px;
    color: var(--third-color);
    z-index: 1;
}

.tabs-wrapper .nav-link svg:hover {
    color: red;
}
.tabs-container .btn-control.button-add {
    height: 42px;
    padding: 0.5rem 1rem;
    //font-weight: 600;
    border-radius: 5px;
    font-size: 14px;
    white-space: nowrap;
}
/*------------------ TABS END ------------------*/
/*------------------ DETAILS TABS START ----------------*/
.datails-tab-container {
    margin: unset;
    padding: unset;
    flex-direction: column;
    .datails-tab-item {
        width: 100%;
        padding: 1rem 0.75rem;
        background-color: var(--tabs-bg);
        border: unset;
        color: var(--sidebar-bg-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-left: 3px solid transparent;
    }
    .datails-tab-item.active {
        color: var(--sidebar-bg-color);
        background-color: var(--bg-color);
        border-left: 3px solid var(--main-color);
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
    }
}
.datails-tab-container {
    padding: 1rem 0 1rem 1rem;
    background-color: var(--tabs-bg);
}
.details-wrapper {
    padding: 2rem;
    border-radius: 0.5rem;
    border: 1px solid var(--card-divader-color);
}
.details-wrapper-spacing {
    padding: 0 1.5rem 0 3rem;
}
.gallery-wrapper {
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid var(--card-divader-color);
}

/*------------------ DATAILS TABS END ------------------*/

/*------------------ MODAL START ------------------*/
.modal-title {
    font-size: 1rem;
    margin-bottom: 0;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--third-color);
}
.modal-text {
    font-size: 0.875rem;
    margin-bottom: 0;
    font-weight: 400;
    color: var(--third-color);
    text-align: center;
}

.modal-header .btn-close {
    padding: 1rem 1rem 0.7rem 1rem;
    margin: -1.3rem -1rem -1rem auto;
    box-shadow: none;
    font-size: 0.875rem;
    color: #dc3545;
}

.modal-header {
    padding: 1rem 1rem 0.7rem 1rem;
    border-bottom: 1px solid var(--card-divader-color);
}
.modal-footer {
    border-top: 1px solid var(--card-divader-color);
}
.modal-body {
    padding: 2rem;
}
.modal-content {
    background-color: var(--bg-color);
}
.modal-for-dropdown {
    .modal-content {
        overflow: visible;
    }
    .modal-body {
        overflow: visible;
    }
    .accordion {
        overflow: visible;
    }
}
/*------------------ MODAL END ------------------*/
/*------------------ ACCORDION START ------------------*/

.accordion-item {
    background-color: var(--bg-color);
    border: 1px solid var(--alert-border-color);
}
.alert-success,
.alert-success .accordion-button {
    color: #155724;
    background-color: var(--alert-success-color);
    border-color: var(--alert-border-color);
}
.alert-warning,
.alert-warning .accordion-button {
    color: #856404;
    background-color: var(--alert-warning-color);
    border-color: var(--alert-border-color);
}
.alert-info,
.alert-info .accordion-button {
    color: #0c5460;
    background-color: var(--alert-info-color);
    border-color: var(--alert-border-color);
}
.accordion-button:focus {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button svg {
    margin-right: 0.5rem;
}
/*------------------ ACCORDION END ------------------*/
/*------------------ ROLES START ------------------*/
#orders-list.card,
#roles-page.card {
    word-wrap: break-word;
    background: var(--bg-color);
    padding: 1.5rem;
    height: 100%;
    border-radius: 5px;
    border: 1px solid var(--bg-color);
    margin-bottom: 1.3rem;
    box-shadow: var(--shadow-parameters) var(--shadow-color);
}
#category-page.card {
    word-wrap: break-word;
    background: var(--bg-color);
    padding: 1.5rem;
    border-radius: 5px;
    height: 100%;
    border: 1px solid var(--bg-color);
    box-shadow: var(--shadow-parameters) var(--shadow-color);
}
.add-role-modal {
    .screens-wrapper {
        .remember-checkbox {
            width: 50%;
            padding-bottom: 1rem;
        }
    }
    .accordion-header button {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 0;
        color: var(--sidebar-bg-color);
    }
}
.add-role-modal .screens-wrapper .remember-checkbox.remember-checkbox-details {
    width: 33.33%;
}
.card h4 {
    font-size: 16px;
    margin-bottom: 0.25rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--third-color);
}
.card .form-control-label {
    font-size: 13px;
    font-weight: 300;
    color: var(--sidebar-bg-color);
}
#orders-list .orders-table-holder a,
#roles-page .orders-table-holder a {
    color: var(--item-table-color);
}
#roles-page .orders-table-holder a:hover,
#orders-list .orders-table-holder a:hover {
    color: var(--main-color);
    text-decoration: none;
}
.linked-td:hover {
    color: var(--main-color);
    cursor: pointer;
}
.table-hover > tbody > tr:hover > * {
    color: var(--item-table-color);
    cursor: pointer;
}
.table-hover > tbody > tr:hover > .linked-td:hover {
    color: var(--main-color);
}
.table-responsive {
    overflow-x: hidden;
}

.table-bordered td,
.table-bordered th {
    border: none;
}
.table-bordered thead td {
    color: var(--item-table-color);
}
.table-bordered thead th {
    background-color: #ecf0fa;
}

.small-row-table .table td,
.table th {
    padding: 0.3rem 0.75rem;
    font-size: 14px;
}
.table > :not(:first-child) {
    border-top: 2px solid #dee2e6;
}
.react-bootstrap-table-pagination {
    margin-top: 2rem;
}
.btn-secondary.dropdown-toggle::after {
    right: 4px;
}
.btn-secondary.dropdown-toggle {
    box-shadow: none !important;
    color: var(--third-color);
    background-color: var(--forms-bg);
    border: 1px solid var(--table-border);
    padding: 0.375rem 1rem;
}
.show > .btn-secondary.dropdown-toggle {
    text-decoration: none;
    background-color: var(--bg-color);
    color: var(--third-color);
    border-color: var(--table-border);
}
.dropdown-common-style .dropdown-item,
.dropdown-common-style .dropdown-menu {
    background-color: var(--forms-bg);
    color: var(--third-color);
}
.dropdown-common-style .dropdown-item:hover {
    background-color: var(--bg-color);
    color: var(--main-color);
}

.react-bootstrap-table-pagination-list .page-link {
    color: var(--third-color);
    height: 100%;
    background-color: var(--forms-bg);
    border: 1px solid var(--table-border);
}
.react-bootstrap-table-pagination-list .page-link:focus {
    box-shadow: none;
}
.react-bootstrap-table-pagination-list .page-link:hover {
    color: var(--main-color);
    text-decoration: none;
    background-color: var(--bg-color);
    border-color: var(--table-border);
}
.react-bootstrap-table-pagination-list .page-item.active .page-link {
    color: var(--main-color);
    text-decoration: none;
    background-color: var(--bg-color);
    border-color: var(--table-border);
}
/*------------------ ROLES END ------------------*/

/*--------------- CATEGORIES START ---------------*/
.btn-control.save-btn.add-category {
    font-weight: 300;
    padding: 0.125rem 0.125rem;
    font-size: 14px;
    margin-top: 0.25rem;
    svg {
        margin-right: 0.25rem;
    }
}
.category-dropdown-tree {
    .category-tree-button {
        color: var(--third-color);
        font-weight: 300;
        font-size: 15px;
        display: inline-block;
        width: 100%;
        padding: 0.5rem;
        border: unset;
        cursor: move;
        background: transparent;
        border-radius: unset;
        box-shadow: unset !important;
        text-align: left;
    }
    .dropdown-menu {
        position: unset !important;
        z-index: 1;
        background: transparent;
        border: unset;
        min-width: unset;
        transform: unset !important;
        padding: 0 0 0 1rem;
    }
    .dropdown-toggle::after {
        top: 1.1875rem;
        right: 0.25rem;
    }
    border-left: 3px solid transparent;
}
.category-dropdown-tree.show {
    border-left: 3px solid var(--main-color);
}
.category-dropdown-tree:hover {
    border-left: 3px solid var(--main-color);
}
.category-dropdown-tree.show .category-tree-button,
.category-dropdown-tree .category-tree-button:active,
.category-dropdown-tree .category-tree-button:hover {
    box-shadow: unset;
    background-color: var(--categories-hover-bg);
    color: var(--third-color);
    text-decoration: none;
    outline: unset;
}
.dropdown-menu > .category-tree-item,
.dropdown-menu > .category-tree-item:focus {
    padding-right: 0.5rem;
}
.category-tree-item,
.category-tree-item:focus {
    color: var(--third-color);
    font-weight: 300;
    font-size: 15px;
    display: inline-block;
    width: 100%;
    padding: 0.5rem 0 0.5rem 1rem;
    border-left: 3px solid transparent;
    cursor: move;
    background-color: transparent;
}
.category-tree-item:hover {
    background-color: var(--categories-hover-bg);
    border-left: 3px solid var(--main-color);
    color: var(--third-color);
    text-decoration: none;
}
.category-tree-item.category-tree-link {
    display: flex;
    flex-direction: column;
}
.drag-over {
    background-color: green !important;
}
.cat-svg {
    margin-right: 0.5rem;
    cursor: pointer;
}
.filters-box-holder {
    padding-top: 1rem;
    overflow: hidden;
    .rst__row {
        display: grid;
        grid-template-columns: min-content auto;
        align-items: center;
        align-content: center;
    }
    .rst__node {
        height: 40px !important;
    }
    .rst__rowWrapper {
        padding: 5px 5px 5px 0;
    }
    .rst__rowTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    .rst__collapseButton,
    .rst__expandButton {
        box-shadow: unset;
    }
    .rst__moveHandle,
    .rst__loadingHandle {
        background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='512' height='512'%3e%3crect id='backgroundrect' width='100%25' height='100%25' x='0' y='0' fill='none' stroke='none'/%3e%3c!-- Font Awesome Pro 5.15.4 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3e%3cg class='currentLayer' style=''%3e%3ctitle%3eLayer 1%3c/title%3e%3cpath d='M352.201 425.775l-79.196 79.196c-9.373 9.373-24.568 9.373-33.941 0l-79.196-79.196c-15.119-15.119-4.411-40.971 16.971-40.97h51.162L228 284H127.196v51.162c0 21.382-25.851 32.09-40.971 16.971L7.029 272.937c-9.373-9.373-9.373-24.569 0-33.941L86.225 159.8c15.119-15.119 40.971-4.411 40.971 16.971V228H228V127.196h-51.23c-21.382 0-32.09-25.851-16.971-40.971l79.196-79.196c9.373-9.373 24.568-9.373 33.941 0l79.196 79.196c15.119 15.119 4.411 40.971-16.971 40.971h-51.162V228h100.804v-51.162c0-21.382 25.851-32.09 40.97-16.971l79.196 79.196c9.373 9.373 9.373 24.569 0 33.941L425.773 352.2c-15.119 15.119-40.971 4.411-40.97-16.971V284H284v100.804h51.23c21.382 0 32.09 25.851 16.971 40.971z' id='svg_1' class='' fill-opacity='1' fill='%23858585'/%3e%3c/g%3e%3c/svg%3e");
        width: 26px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px;
        background-color: #ebebeb;
        border: unset;
        padding: 0 5px;
        box-shadow: unset;
        border-radius: unset;
        height: 26px;
    }
    .rst__toolbarButton {
        display: flex;
    }
    .rst__rowContents {
        border-radius: unset;
        border: unset;
        background-color: transparent;
        box-shadow: unset;
        padding-left: unset;
        max-width: 100%;
        min-width: unset;
    }
    .rst__rowLabel {
        display: none;
    }
    .tree-button {
        border: unset;
        background: transparent;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: break-spaces;
        line-height: 1rem;
        text-align: left;
        max-height: 34px;
        padding-right: unset;
        text-transform: lowercase;
    }
    .tree-button:first-letter {
        text-transform: uppercase;
    }
}

div.rowContents {
    background-color: yellow;
}
/*---------------- CATEGORIES END ----------------*/
.bootstrap-demo .dropdown-trigger {
    border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555555;
}

.bootstrap-demo .toggle {
    font: normal normal normal 12px/1 FontAwesome;
    color: #555555;
}

.bootstrap-demo .toggle.collapsed::after {
    content: "\f067";
}

.bootstrap-demo .toggle.expanded::after {
    content: "\f068";
}

.bootstrap-demo .root {
    padding: 0px;
    margin: 0px;
}

/*--------------- IMAGE CROP START ---------------*/

.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
}

.controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 40px;
    display: flex;
    align-items: center;
    input::-webkit-slider-runnable-track {
        background-color: var(--sidebar-bg-color);
    }
}

.slider {
    padding: 22px 0px;
}

.zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    border-top: 5px solid #ffffff;
    border-bottom: 5px solid #ffffff;
    background: #3f51b5;
    width: 100%;
}

.zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
}

.controls:hover input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.crop-modal .modal-content {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.img-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.no-img-container {
    position: relative;
    text-align: center;
    border: 0.0625rem dashed #0000;
    border-color: var(--forms-border);
    border-radius: 0.25rem;
    padding: 1.5rem 0;
}
.no-img-container img {
    object-fit: contain;
    max-width: 120px;
}
.no-img-container .no-img-text {
    margin-bottom: unset;
    font-size: 0.875rem;
}
.no-img-container:hover {
    background: #ffffff;
}
.selected-img-container {
    position: relative;
    img {
        object-fit: contain;
        width: 100%;
    }
    button {
        position: absolute;
        top: 4px;
        right: 4px;
        z-index: 1;
        color: var(--sidebar-bg-color);
        background: transparent;
        border: unset;
    }
}

/*--------------- IMAGE CROP END ---------------*/
/*--------------- PRODUCT START ---------------*/
.seo-text {
    font-size: 15px;
    line-height: 20px;
    color: var(--third-color);
}
.form-fields-separation {
    border-top: 1px solid var(--card-divader-color);
    margin-top: 0.5rem;
}
.form-field-separation {
    border-top: 1px solid var(--card-divader-color);
    margin: 1rem 0;
}
.accordion {
    margin-bottom: 2rem;
}
.galley-container {
    grid-gap: 0.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    -webkit-user-select: none;
    user-select: none;
    .selected-img-container {
        border: 1px solid var(--forms-border);
        height: 150px;
        img {
            height: 100%;
        }
    }
    .selected-img-container:first-child {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
        height: 308px;
    }
    .no-img-container {
        grid-column: 1 / span 4;
    }
    .add-more-img-container {
        grid-column: unset;
        position: relative;
        display: grid;
        grid-template-rows: min-content auto;
        height: 148px;
        img {
            position: absolute;
            top: 0;
            max-width: unset;
            height: 100%;
            width: auto;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }
        .add-more-img-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
        }
    }
}
.galley-container.galley-container-select {
    .selected-img-container:first-child {
        grid-column: unset;
        grid-row: unset;
        height: 148px;
    }
    .selected-img-var {
        border: 1px solid var(--main-color);
    }
    // .main-img-var
    .selected-img-var.main-img-var {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
        height: 308px;
    }
}
.remove-options {
    font-size: 0.8125rem;
    margin-bottom: 0;
    color: red;
    background-color: transparent;
    border: unset;
    padding: 0 0 0 0.5rem;
    width: auto;
    white-space: nowrap;
}
.remove-options:hover {
    text-decoration: underline;
}
.label-with-remove {
    width: auto;
    flex-shrink: unset;
    padding: unset;
}
.products-list-holder {
    p {
        font-size: 0.9375rem !important;
        margin-bottom: 0;
        color: var(--third-color);
    }
    .row {
        margin: unset;
    }
    .row:not(:last-child) {
        border-bottom: 1px solid var(--card-divader-color);
        margin-bottom: 0.75rem;
        padding-bottom: 0.75rem;
    }
}
.warehouse-item-name {
    font-size: 0.9375rem;
    margin-bottom: 0;
    color: var(--third-color);
}

.products-table {
    .table-bordered tr th:first-child,
    .table-bordered tr td:first-child {
        padding-left: unset;
    }
    .table-bordered th,
    .table-bordered td {
        border: unset;
        vertical-align: middle;
    }
    .table-bordered thead tr {
        border-top: unset;
    }
    .table > :not(:first-child) {
        border-top: 1px solid var(--card-divader-color);
    }
    .table-bordered tbody tr {
        border: unset;
        border-bottom: 1px dotted var(--card-divader-color);
    }
    .table-bordered thead th {
        color: var(--third-color);
        font-weight: 600;
        font-size: 0.9375rem;
    }
    .table-bordered tbody td {
        font-size: 0.9375rem;
        margin-bottom: 0;
        color: var(--third-color);
    }
}
.table-row-hover {
    .table-hover > tbody > tr:hover > * {
        color: var(--main-color);
        cursor: pointer;
    }
}
.statistics-box-holder {
    padding-bottom: 2rem;
}
/*--------------- PRODUCT END ---------------*/

/*--------------- ORDERS START ---------------*/
.order-data-accordion {
    ul {
        padding: unset;
    }
    li {
        list-style: none;
        font-size: 0.9375rem;
        font-weight: 600;
        color: var(--third-color);
        span {
            font-weight: 300;
            padding-left: 0.25rem;
        }
    }
}
.order-details-accordion {
    h5 {
        font-size: 0.9375rem;
        font-weight: 600;
        margin-bottom: 0;
        color: var(--third-color);
    }
    hr {
        margin: 0.5rem 0;
        border-top: 1px solid var(--card-divader-color);
    }
    p {
        font-size: 0.9375rem;
        font-weight: 400;
        margin-bottom: 0;
        color: var(--small-text-color);
    }
    span {
        font-size: 0.9375rem;
        font-weight: 600;
        float: right;
    }
}
.order-items-accordion {
    .order-items-header-accordion {
        border-bottom: 1px solid var(--card-divader-color);
        padding-bottom: 0.5rem;
        p {
            color: var(--third-color);
            font-size: 0.8125rem;
            margin-bottom: unset;
        }
    }
    .table-for-products {
        padding: 0.5rem 0;
        border-bottom: 1px solid var(--card-divader-color);
        .row {
            align-items: center;
            p {
                color: var(--third-color);
                font-size: 0.8125rem;
                font-weight: 600;
                margin: unset;
            }
        }
        .buttons-col a {
            align-items: center;
            border-radius: 0.25rem;
            display: flex;
            height: 36px;
            justify-content: center;
            width: 36px;
        }
    }
}
.order-status-checkboxs {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0.3125rem;
    .form-check {
        padding-bottom: 0.5rem;
        p {
            color: var(--third-color);
            font-size: 0.8125rem;
            margin-bottom: unset;
        }
    }
}
.accordion-p-0 {
    padding: unset;
}
.accordion-p-0-body {
    padding: 1rem;
    padding-bottom: 0;
}
.order-details-page {
    ul {
        margin: unset;
    }
}
.order-status-buttons {
    padding: 0 1.25rem;
    display: flex;
    justify-content: space-between;
    .btn-control.button-add {
        font-weight: 600;
        padding: 0.4rem 1rem;
        border: 1px solid #17a2b8;
    }
    .btn-control.button-add:hover {
        border: unset;
    }
}
.order-note-select {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    .select-style {
        width: 150px;
        margin-left: 0.25rem;
    }
}
.history-note {
    padding-left: 0;
    margin-bottom: 0;
    hr {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        border-top: 1px solid var(--alert-border-color);
    }
}
.order-note-history {
    .history-note li {
        list-style: none;
        font-size: 13px;
        color: var(--third-color);
    }

    .history-note li small {
        font-weight: 600;
        color: var(--third-color);
    }
    .badge {
        padding: 0.25em 0.4em 0.25rem;
        margin-right: 0.3rem;
    }
    .badge-success {
        color: #ffffff;
        background-color: #28a745;
    }
    .badge-info {
        color: #ffffff;
        background-color: #17a2b8;
    }
    .badge-warning {
        color: #212529;
        background-color: #ffc107;
    }
}
/*--------------- ORDERS END ---------------*/
.checkbox-style {
    background-color: var(--dark-silver);
}
.orders-item-holder h5 {
    font-weight: 400;
    padding: 0.5rem;
    font-size: 17px;
    background-color: #ecf0fa;
    border-radius: 0.25rem;
}
.orders-item-holder ul {
    padding: 1rem;
}
.orders-item-holder {
    margin-bottom: 2rem;
}
.orders-item-holder .order-items-accordion {
    padding: 1rem;
}
.orders-item-holder .order-details-accordion {
    padding: 1rem;
}
.orders-item-holder .buyers {
    padding: 1rem;
}
.mediaPrintLogo {
    display: none !important;
}

@media (min-width: 1200px) {
    .col-xl-1 {
        width: 8.33333% !important;
        box-sizing: border-box;
    }

    .col-xl-2 {
        width: 16.66667% !important;
        box-sizing: border-box;
    }

    .col-xl-3 {
        width: 25% !important;
        box-sizing: border-box;
    }

    .col-xl-4 {
        width: 33.33333% !important;
        box-sizing: border-box;
    }

    .col-xl-5 {
        width: 41.66667% !important;
        box-sizing: border-box;
    }

    .col-xl-6 {
        width: 50% !important;
        box-sizing: border-box;
    }

    .col-xl-7 {
        width: 58.33333% !important;
        box-sizing: border-box;
    }

    .col-xl-8 {
        width: 66.66667% !important;
        box-sizing: border-box;
    }

    .col-xl-9 {
        width: 75% !important;
        box-sizing: border-box;
    }

    .col-xl-10 {
        width: 83.33333% !important;
        box-sizing: border-box;
    }

    .col-xl-11 {
        width: 91.66667% !important;
        box-sizing: border-box;
    }

    .col-xl-12 {
        width: 100% !important;
        box-sizing: border-box;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .col-lg-1 {
        width: 8.33333% !important;
        box-sizing: border-box;
    }

    .col-lg-2 {
        width: 16.66667% !important;
        box-sizing: border-box;
    }

    .col-lg-3 {
        width: 25% !important;
        box-sizing: border-box;
    }

    .col-lg-4 {
        width: 33.33333% !important;
        box-sizing: border-box;
    }

    .col-lg-5 {
        width: 41.66667% !important;
        box-sizing: border-box;
    }

    .col-lg-6 {
        width: 50% !important;
        box-sizing: border-box;
    }

    .col-lg-7 {
        width: 58.33333% !important;
        box-sizing: border-box;
    }

    .col-lg-8 {
        width: 66.66667% !important;
        box-sizing: border-box;
    }

    .col-lg-9 {
        width: 75% !important;
        box-sizing: border-box;
    }

    .col-lg-10 {
        width: 83.33333% !important;
        box-sizing: border-box;
    }

    .col-lg-11 {
        width: 91.66667% !important;
        box-sizing: border-box;
    }

    .col-lg-12 {
        width: 100% !important;
        box-sizing: border-box;
    }
}

@media (max-width: 900px) {
    #sidebar {
        left: auto;
        min-width: 0;
        max-width: 0;
    }
    .router-container.side-open {
        padding-left: 0;
        width: auto;
    }
    .router-container {
        padding-left: 0;
        width: 100%;
    }
    #sidebar .logo img {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .col-md-1 {
        width: 8.33333% !important;
        box-sizing: border-box;
    }

    .col-md-2 {
        width: 16.66667% !important;
        box-sizing: border-box;
    }

    .col-md-3 {
        width: 25% !important;
        box-sizing: border-box;
    }

    .col-md-4 {
        width: 33.33333% !important;
        box-sizing: border-box;
    }

    .col-md-5 {
        width: 41.66667% !important;
        box-sizing: border-box;
    }

    .col-md-6 {
        width: 50% !important;
        box-sizing: border-box;
    }

    .col-md-7 {
        width: 58.33333% !important;
        box-sizing: border-box;
    }

    .col-md-8 {
        width: 66.66667% !important;
        box-sizing: border-box;
    }

    .col-md-9 {
        width: 75% !important;
        box-sizing: border-box;
    }

    .col-md-10 {
        width: 83.33333% !important;
        box-sizing: border-box;
    }

    .col-md-11 {
        width: 91.66667% !important;
        box-sizing: border-box;
    }

    .col-md-12 {
        width: 100% !important;
        box-sizing: border-box;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .col-sm-1 {
        width: 8.33333% !important;
        box-sizing: border-box;
    }

    .col-sm-2 {
        width: 16.66667% !important;
        box-sizing: border-box;
    }

    .col-sm-3 {
        width: 25% !important;
        box-sizing: border-box;
    }

    .col-sm-4 {
        width: 33.33333% !important;
        box-sizing: border-box;
    }

    .col-sm-5 {
        width: 41.66667% !important;
        box-sizing: border-box;
    }

    .col-sm-6 {
        width: 50% !important;
        box-sizing: border-box;
    }

    .col-sm-7 {
        width: 58.33333% !important;
        box-sizing: border-box;
    }

    .col-sm-8 {
        width: 66.66667% !important;
        box-sizing: border-box;
    }

    .col-sm-9 {
        width: 75% !important;
        box-sizing: border-box;
    }

    .col-sm-10 {
        width: 83.33333% !important;
        box-sizing: border-box;
    }

    .col-sm-11 {
        width: 91.66667% !important;
        box-sizing: border-box;
    }

    .col-sm-12 {
        width: 100% !important;
        box-sizing: border-box;
    }
}

@media (max-width: 575.98px) {
    .col-xs-1 {
        width: 8.33333% !important;
        box-sizing: border-box;
    }

    .col-xs-2 {
        width: 16.66667% !important;
        box-sizing: border-box;
    }

    .col-xs-3 {
        width: 25% !important;
        box-sizing: border-box;
    }

    .col-xs-4 {
        width: 33.33333% !important;
        box-sizing: border-box;
    }

    .col-xs-5 {
        width: 41.66667% !important;
        box-sizing: border-box;
    }

    .col-xs-6 {
        width: 50% !important;
        box-sizing: border-box;
    }

    .col-xs-7 {
        width: 58.33333% !important;
        box-sizing: border-box;
    }

    .col-xs-8 {
        width: 66.66667% !important;
        box-sizing: border-box;
    }

    .col-xs-9 {
        width: 75% !important;
        box-sizing: border-box;
    }

    .col-xs-10 {
        width: 83.33333% !important;
        box-sizing: border-box;
    }

    .col-xs-11 {
        width: 91.66667% !important;
        box-sizing: border-box;
    }

    .col-xs-12 {
        width: 100% !important;
        box-sizing: border-box;
    }
}

@media (max-width: 380px) {
    .side-open #sidebar {
        min-width: 12rem;
        max-width: 12rem;
    }
}

@media print {
    .main-wrapper {
        padding: 0;
    }
    .side-open #sidebar {
        display: none;
    }
    .sideNavIcon {
        display: none !important;
    }
    .mediaPrintLogo {
        display: block !important;
    }
    .router-container.side-open {
        padding-left: 0 !important;
        width: auto !important;
    }
    .side-open #sidebar {
        display: none !important;
    }
}
