@import "../../../variables.scss";

.toolBarStyle {
    text-align: left;
}

.titleStyle {
    margin: 1rem !important;
    display: inline;
}

.tabsBtnCreate {
    right: 0.5rem !important;
    background-color: $lightBlue !important;
    color: $white !important;
    display: inline-block;
    float: right;
    position: relative;
}

.tabsBtnCreate:hover {
    background-color: $lightGrey !important;
    color: $black !important;
    cursor: pointer !important;
}

.wrapper {
    margin-top: 2rem;
    max-height: calc(100vh - 26.75rem);

    table {
        border: 1px solid rgba(224, 224, 224, 1);

        tr {
            & > td {
                padding: 0.4rem 2rem;
                color: var(--dark-silver);
                box-sizing: border-box;
                svg {
                    fill: var(--transparent-blue);
                    font-size: 1rem !important;
                }
            }

            &:hover {
                td {
                    color: var(--main-color);
                }
            }
        }
    }
}

.toolbarButtonsGroup {
    display: flex;
}

.toolbarButtons {
    background-color: $lightGrey !important;
    color: $black !important;
}

// RESPONSIVE

@media only screen and (max-width: 900px) {
    .wrapper {
        max-height: none;
    }
}
